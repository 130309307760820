import React from 'react'

function LogoBlooming() {
    return (
        <svg 
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 91.5 83.5" 
            style={{fill: "#CA8F3E", height: "100%"}}
            >

                    <path d="M74.15,24.66C72.4,10.79,60.57,0,46.23,0c-9.72,0-18.47,5-23.52,12.49
                        c-0.82,1.22-1.99,2.72-2.91,3.78c0,0,7.32,0.5,7.39,0.5s11.23,21.11,11.23,21.11l4.12-1.92l0.85-18.26l8.31,7.04l-7.6,6.47
                        l-0.36,5.61l-5.54,2.56l-1.63-1.85l-10.23,7.46v6.08c-0.01-0.09-0.01-0.18-0.02-0.27c-0.71,5.18-4.23,9.12-8.47,9.12
                        c-4.75,0-8.6-4.95-8.6-11.05c0-4.41,2.01-8.21,4.91-9.99c-0.32-0.02-0.64-0.04-0.97-0.04C5.9,38.84,0,44.74,0,52.02
                        S5.9,65.2,13.18,65.2c6.96,0,12.65-5.39,13.15-12.21V83.5h3.62V51.18c0.91,0.65,1.86,1.24,2.84,1.77V83.5h3.62V54.6
                        c3.05,1.14,6.36,1.76,9.81,1.76c11.77,0,21.85-7.23,26.05-17.48h10.48V56h3.62V35.18H73.5c0.56-2.18,0.86-4.47,0.87-6.82h13.5v17.13
                        h3.62V24.66H74.15z"/>
                </svg>
    )
}

export default LogoBlooming
