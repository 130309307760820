import React from 'react'

export default function LogoKorina() {
  return (
    <svg width="242" height="115" viewBox="0 0 242 115" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="242" height="115" />
      <g clipPath="url(#clip0_0_1)">
        <path d="M18.53 7.22C16.67 15.06 17.72 23.77 22.35 30.36C26.98 36.95 35.44 40.97 43.34 39.39C45.21 39.01 47.2 38.2 48.04 36.48" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M20.09 26.38C19.67 23.28 21.32 19.11 23.6 16.95C26.64 14.08 31.91 13.81 36.07 14.35" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M25.24 33.12C20.88 34.62 15.99 34.55 11.67 32.93C7.35 31.32 3.62 28.16 1.3 24.17" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M76.19 7.22C63.83 7.22 53.93 12.61 49.66 17.61C45.29 22.73 43.6 28.01 42.2 34.6" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M49.66 19.43C52.56 21.77 56.31 23.05 60.04 22.97" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M57.91 11.47C61.47 14.82 67.05 15.65 70.01 14.18" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M11.06 39.9C12.53 44.29 15.92 48.01 20.16 49.87C22.2 50.77 24.39 51.25 26.6 51.52C29.61 51.9 32.73 51.89 35.6 50.91C38.47 49.93 41.07 47.86 42.17 45.03" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M18.07 48.79C13.74 49.11 9.73 52.15 8.26 56.24" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M11.57 52.21C13.38 53.75 14.34 54.68 16.32 57.45C20.72 63.61 20.95 77.42 14.09 82.99" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M18.06 66.55C12.08 67.78 7.10001 72.9 6.03001 78.91" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M17.63 78.77C21.18 83.37 22.33 89.71 20.63 95.26" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M44.52 52.16C41.72 58.97 43.84 66.79 46.94 73.46C49.83 79.7 53.56 85.55 58 90.8" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M47.64 72.71C52.44 71.39 57.71 71.87 62.2 74.03" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M52.13 82.88C57.35 82.88 61.81 84.1 66.21 86.12C68.52 87.18 72.6 90.47 74.19 92.81" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M44.36 66.07C41.21 65.4 37.96 64.69 35.33 62.83C32.7 60.97 30.83 57.63 31.68 54.52" stroke="#1E312F" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M3.31001 49.95C4.20001 50.99 5.52001 51.45 6.78001 51.28C7.48001 51.18 8.04001 50.72 8.30001 50.1C6.31001 48.31 3.76001 49.18 4.53001 48.94C6.22001 48.42 7.59001 48.98 8.44001 49.54C8.45001 49.4 8.45001 49.26 8.43001 49.12C8.29001 48.06 7.33001 47.31 6.27001 47.45C5.21001 47.59 4.24001 46.84 4.11001 45.78C3.97001 44.72 4.42001 43.93 3.76001 44.5C2.13001 45.88 1.93001 48.32 3.31001 49.95Z" fill="#1E312F" />
        <path d="M57.01 15.14C55.7 15.53 54.75 16.55 54.39 17.78C54.2 18.46 54.39 19.16 54.85 19.64C57.29 18.55 57.53 15.87 57.44 16.66C57.24 18.42 56.17 19.44 55.3 19.99C55.42 20.06 55.55 20.11 55.69 20.16C56.71 20.46 57.79 19.88 58.09 18.86C58.39 17.84 59.47 17.26 60.49 17.56C61.51 17.86 62.05 18.59 61.81 17.76C61.21 15.7 59.06 14.53 57.01 15.14Z" fill="#1E312F" />
        <path d="M34.22 31.52C33.77 32.81 34.04 34.18 34.83 35.19C35.27 35.75 35.96 36 36.61 35.9C37.15 33.28 35.12 31.52 35.7 32.06C37.01 33.25 37.21 34.72 37.16 35.74C37.29 35.68 37.41 35.61 37.52 35.52C38.36 34.87 38.52 33.66 37.87 32.81C37.22 31.97 37.37 30.75 38.22 30.1C39.06 29.45 39.97 29.43 39.15 29.15C37.13 28.44 34.92 29.51 34.22 31.52Z" fill="#1E312F" />
        <path d="M31.67 43.29C30.5 44 29.85 45.24 29.82 46.52C29.81 47.23 30.18 47.86 30.75 48.2C32.82 46.51 32.36 43.86 32.48 44.65C32.74 46.4 31.97 47.66 31.28 48.42C31.41 48.45 31.55 48.48 31.7 48.48C32.77 48.51 33.66 47.67 33.68 46.6C33.71 45.53 34.6 44.69 35.66 44.72C36.73 44.75 37.44 45.31 36.98 44.57C35.87 42.75 33.49 42.17 31.67 43.29Z" fill="#1E312F" />
        <path d="M8.06001 39.42C9.43001 39.42 10.63 38.7 11.32 37.63C11.7 37.03 11.71 36.3 11.4 35.71C8.75001 36.07 7.76001 38.57 8.07001 37.84C8.76001 36.21 10.07 35.53 11.06 35.25C10.96 35.15 10.85 35.06 10.73 34.98C9.84001 34.4 8.64001 34.65 8.06001 35.55C7.48001 36.44 6.28001 36.7 5.39001 36.12C4.50001 35.54 4.18001 34.69 4.18001 35.56C4.19001 37.69 5.92001 39.42 8.06001 39.42Z" fill="#1E312F" />
        <path d="M8.00001 26.78C8.44001 28.08 9.5 28.99 10.73 29.3C11.42 29.47 12.11 29.24 12.58 28.77C11.4 26.37 8.71001 26.23 9.51001 26.29C11.27 26.43 12.34 27.46 12.92 28.3C12.98 28.18 13.03 28.04 13.07 27.9C13.34 26.87 12.72 25.81 11.68 25.55C10.65 25.28 10.03 24.23 10.29 23.2C10.56 22.17 11.26 21.6 10.44 21.88C8.41 22.56 7.32001 24.75 8.00001 26.78Z" fill="#1E312F" />
        <path d="M10.07 13.14C11.04 14.11 12.39 14.46 13.64 14.18C14.33 14.03 14.85 13.52 15.06 12.89C12.94 11.27 10.46 12.33 11.21 12.04C12.85 11.38 14.26 11.83 15.16 12.33C15.16 12.19 15.15 12.05 15.12 11.91C14.9 10.87 13.88 10.2 12.83 10.42C11.79 10.64 10.76 9.97 10.54 8.93C10.32 7.89 10.7 7.06 10.09 7.68C8.57 9.17 8.56 11.62 10.07 13.14Z" fill="#1E312F" />
        <path d="M67.01 78.58C65.69 78.23 64.34 78.61 63.4 79.47C62.88 79.95 62.68 80.65 62.82 81.3C65.47 81.64 67.07 79.47 66.58 80.1C65.49 81.49 64.05 81.81 63.02 81.83C63.09 81.95 63.17 82.07 63.27 82.17C63.98 82.96 65.21 83.03 66 82.31C66.79 81.6 68.01 81.66 68.73 82.45C69.44 83.24 69.53 84.15 69.75 83.3C70.31 81.25 69.08 79.13 67.01 78.58Z" fill="#1E312F" />
        <path d="M38.96 72.01C40.12 71.28 40.75 70.03 40.76 68.76C40.76 68.05 40.38 67.43 39.81 67.09C37.76 68.81 38.26 71.45 38.14 70.67C37.86 68.92 38.6 67.65 39.28 66.88C39.14 66.85 39 66.83 38.86 66.83C37.79 66.82 36.92 67.67 36.9 68.74C36.89 69.81 36.01 70.66 34.94 70.65C33.87 70.64 33.15 70.08 33.62 70.82C34.76 72.62 37.15 73.15 38.96 72.01Z" fill="#1E312F" />
        <path d="M27.38 71.75C26.91 73.04 25.83 73.92 24.59 74.2C23.9 74.35 23.21 74.11 22.76 73.62C24 71.25 26.69 71.18 25.89 71.22C24.12 71.32 23.04 72.32 22.43 73.15C22.37 73.02 22.32 72.89 22.29 72.75C22.05 71.71 22.7 70.67 23.74 70.43C24.78 70.19 25.43 69.15 25.19 68.11C24.95 67.07 24.26 66.49 25.07 66.78C27.08 67.52 28.11 69.74 27.38 71.75Z" fill="#1E312F" />
        <path d="M52.16 31.97C51.21 32.96 49.86 33.33 48.61 33.08C47.91 32.94 47.38 32.44 47.17 31.81C49.26 30.14 51.75 31.16 51.01 30.88C49.36 30.25 47.96 30.73 47.07 31.25C47.07 31.11 47.07 30.97 47.1 30.83C47.3 29.78 48.31 29.09 49.36 29.29C50.41 29.49 51.42 28.8 51.62 27.75C51.82 26.7 51.42 25.89 52.05 26.49C53.59 27.97 53.64 30.42 52.16 31.97Z" fill="#1E312F" />
        <path d="M23.36 41.47C24.17 42.57 24.31 43.96 23.85 45.16C23.59 45.82 23.01 46.26 22.36 46.37C21.07 44.02 22.5 41.74 22.1 42.43C21.2 43.95 21.43 45.41 21.79 46.38C21.65 46.36 21.52 46.33 21.38 46.28C20.38 45.91 19.88 44.79 20.25 43.79C20.62 42.79 20.12 41.68 19.12 41.3C18.12 40.93 17.25 41.18 17.95 40.66C19.67 39.39 22.09 39.75 23.36 41.47Z" fill="#1E312F" />
        <path d="M13.78 88.56C14.61 87.47 15.91 86.95 17.18 87.05C17.89 87.11 18.47 87.55 18.76 88.15C16.87 90.04 14.28 89.31 15.05 89.51C16.76 89.94 18.1 89.31 18.92 88.7C18.94 88.84 18.95 88.98 18.94 89.12C18.86 90.18 17.93 90.98 16.87 90.9C15.81 90.82 14.88 91.62 14.8 92.68C14.72 93.74 15.21 94.51 14.51 93.98C12.81 92.69 12.48 90.26 13.78 88.56Z" fill="#1E312F" />
        <path d="M6.45001 87.18C6.62001 85.82 7.48001 84.72 8.63001 84.17C9.27001 83.87 10 83.95 10.54 84.33C9.86001 86.91 7.25001 87.59 8.02001 87.37C9.72001 86.88 10.56 85.67 10.96 84.73C11.05 84.84 11.12 84.96 11.19 85.09C11.66 86.05 11.25 87.21 10.29 87.67C9.33001 88.14 8.93001 89.29 9.39001 90.25C9.86001 91.21 10.66 91.63 9.80001 91.52C7.69001 91.24 6.18001 89.3 6.45001 87.18Z" fill="#1E312F" />
        <path d="M7.61001 61.05C8.90001 60.58 10.27 60.83 11.29 61.6C11.85 62.03 12.11 62.71 12.03 63.37C9.42001 63.95 7.63001 61.94 8.17001 62.52C9.38001 63.81 10.85 63.99 11.87 63.92C11.81 64.05 11.74 64.17 11.66 64.29C11.02 65.14 9.81001 65.32 8.96001 64.68C8.11001 64.04 6.89001 64.22 6.26001 65.07C5.62001 65.92 5.62001 66.83 5.32001 66.01C4.58001 64.01 5.61001 61.79 7.61001 61.05Z" fill="#1E312F" />
        <path d="M10.12 76.14C10.95 75.05 12.25 74.53 13.52 74.63C14.23 74.69 14.81 75.13 15.1 75.73C13.21 77.62 10.62 76.89 11.39 77.09C13.1 77.52 14.44 76.89 15.26 76.28C15.28 76.42 15.29 76.56 15.28 76.7C15.2 77.76 14.27 78.56 13.21 78.48C12.15 78.4 11.22 79.2 11.14 80.26C11.06 81.32 11.55 82.09 10.85 81.56C9.15001 80.27 8.82001 77.84 10.12 76.14Z" fill="#1E312F" />
        <path d="M55.8 79.78C54.62 79.08 53.96 77.85 53.91 76.58C53.89 75.87 54.26 75.24 54.82 74.89C56.91 76.55 56.48 79.21 56.59 78.42C56.83 76.67 56.05 75.41 55.35 74.66C55.48 74.62 55.62 74.6 55.77 74.6C56.84 74.56 57.73 75.39 57.78 76.45C57.82 77.52 58.72 78.35 59.79 78.3C60.86 78.26 61.56 77.69 61.11 78.43C60.01 80.28 57.64 80.88 55.8 79.78Z" fill="#1E312F" />
        <path d="M62.63 92.66C61.7 91.65 61.41 90.29 61.73 89.05C61.91 88.36 62.44 87.86 63.08 87.69C64.62 89.88 63.45 92.3 63.78 91.58C64.5 89.97 64.11 88.54 63.65 87.62C63.79 87.62 63.93 87.64 64.07 87.68C65.1 87.94 65.73 88.99 65.47 90.03C65.21 91.06 65.83 92.11 66.87 92.38C67.9 92.64 68.74 92.29 68.1 92.88C66.53 94.32 64.08 94.23 62.63 92.66Z" fill="#1E312F" />
        <path d="M52.85 65.21C52.47 66.53 51.45 67.48 50.23 67.85C49.55 68.05 48.85 67.85 48.36 67.4C49.44 64.95 52.12 64.7 51.32 64.79C49.57 65 48.55 66.08 48 66.94C47.93 66.82 47.87 66.69 47.83 66.55C47.52 65.53 48.09 64.45 49.12 64.14C50.14 63.83 50.72 62.75 50.41 61.73C50.1 60.71 49.37 60.17 50.2 60.41C52.26 61.01 53.44 63.16 52.85 65.21Z" fill="#1E312F" />
        <path d="M40.4 57.22C40.38 58.59 39.64 59.78 38.56 60.45C37.95 60.82 37.23 60.82 36.64 60.5C37.04 57.86 39.56 56.91 38.82 57.21C37.18 57.88 36.48 59.18 36.18 60.16C36.08 60.06 35.99 59.95 35.92 59.83C35.35 58.93 35.63 57.73 36.53 57.17C37.43 56.6 37.71 55.41 37.14 54.51C36.57 53.61 35.73 53.28 36.6 53.29C38.73 53.32 40.43 55.08 40.4 57.22Z" fill="#1E312F" />
        <path d="M22.03 58.53C21.36 57.33 21.4 55.94 22.01 54.81C22.35 54.19 22.98 53.82 23.64 53.8C24.62 56.29 22.91 58.37 23.4 57.74C24.48 56.34 24.44 54.86 24.21 53.87C24.34 53.91 24.48 53.96 24.6 54.02C25.54 54.52 25.9 55.69 25.41 56.63C24.91 57.57 25.27 58.74 26.22 59.24C27.16 59.74 28.06 59.6 27.3 60.02C25.43 61.07 23.07 60.4 22.03 58.53Z" fill="#1E312F" />
        <path d="M95.84 92.86C92.5 92.86 89.55 92.02 87.01 90.34C84.46 88.66 82.52 86.38 81.19 83.49C79.86 80.61 79.19 77.35 79.19 73.72C79.19 70.98 79.58 68.44 80.35 66.1C81.12 63.76 82.23 61.73 83.66 60.01C85.09 58.29 86.88 56.95 89.01 55.97C91.14 54.99 93.51 54.51 96.11 54.51C99.56 54.51 102.56 55.36 105.09 57.05C107.62 58.74 109.53 61.02 110.79 63.88C112.06 66.74 112.69 70 112.69 73.65C112.69 77.33 112.04 80.6 110.74 83.47C109.44 86.34 107.5 88.62 104.92 90.32C102.34 92.02 99.32 92.86 95.84 92.86ZM96.15 89.53C98.84 89.53 101.11 88.82 102.96 87.39C104.81 85.97 106.16 84.09 107 81.75C107.84 79.42 108.26 76.74 108.26 73.71C108.26 68.96 107.25 65.13 105.23 62.24C103.21 59.35 100.14 57.9 96.01 57.9C93.32 57.9 91.03 58.62 89.13 60.07C87.23 61.52 85.85 63.41 84.98 65.76C84.1 68.1 83.67 70.79 83.67 73.82C83.67 78.42 84.74 82.19 86.88 85.12C89.02 88.06 92.11 89.53 96.15 89.53Z" fill="#1E312F" />
        <path d="M120.98 92.05C120.94 70.41 120.91 58.12 120.91 55.18H124.34L124.61 62.45C125.93 60.25 127.61 58.46 129.66 57.07C131.7 55.68 133.83 54.98 136.05 54.98C136.97 54.98 137.96 55.06 139.01 55.22L138.71 58.69C137.9 58.56 137.05 58.49 136.15 58.49C133.03 58.49 130.41 59.72 128.29 62.17C126.17 64.63 125.11 67.54 125.11 70.9V92.06H120.98V92.05Z" fill="#1E312F" />
        <path d="M145.4 92.05V55.18H149.61V92.05H145.4Z" fill="#1E312F" />
        <path d="M159.94 92.05V55.18H163.57L164.14 60.87C165.46 58.9 167.24 57.34 169.47 56.21C171.7 55.08 174.11 54.51 176.69 54.51C180.93 54.51 184.05 55.68 186.04 58.01C188.03 60.34 189.03 64.07 189.03 69.18V92.06H184.82V67.29C184.71 64.02 184.01 61.66 182.73 60.23C181.45 58.8 179.35 58.08 176.44 58.08C173.1 58.08 170.37 58.92 168.25 60.6C166.13 62.28 164.8 64.46 164.26 67.13C164.17 68.18 164.13 69.28 164.13 70.43V92.06H159.94V92.05Z" fill="#1E312F" />
        <path d="M208.55 92.86C205.07 92.86 202.29 91.98 200.21 90.24C198.12 88.49 197.08 85.99 197.08 82.74C197.08 80.81 197.49 79.14 198.32 77.71C199.15 76.29 200.36 75.13 201.94 74.24C203.52 73.35 205.33 72.66 207.36 72.17C209.39 71.68 211.75 71.33 214.44 71.13C217.31 70.95 219.44 70.8 220.83 70.69V66.59C220.83 63.76 220.19 61.64 218.9 60.21C217.61 58.79 215.54 58.07 212.69 58.07C208.79 58.07 205.14 59 201.76 60.86C201.67 60.64 201.46 60.15 201.14 59.4C200.81 58.65 200.59 58.07 200.45 57.67C201.84 56.77 203.66 56.02 205.9 55.42C208.14 54.81 210.34 54.51 212.49 54.51C216.91 54.51 220.08 55.46 222.01 57.35C223.94 59.25 224.9 62.6 224.9 67.43V92.06H221.64C221.53 91.54 221.36 90.52 221.15 89C220.94 87.48 220.83 86.69 220.83 86.65C217.94 90.79 213.84 92.86 208.55 92.86ZM208.68 89.53C211.55 89.53 214.1 88.68 216.33 86.99C218.56 85.3 220.03 82.95 220.72 79.94V73.65C217.49 73.92 215.86 74.05 215.84 74.05C210.37 74.54 206.57 75.44 204.43 76.74C202.29 78.04 201.23 80.06 201.23 82.8C201.23 85.04 201.91 86.73 203.27 87.85C204.64 88.97 206.44 89.53 208.68 89.53Z" fill="#1E312F" />
        <path d="M65.2 36.39C79.62 31.85 94.39 28.21 109.46 26.55C116.99 25.72 124.59 25.39 132.18 25.83C139.75 26.26 147.33 27.55 154.52 30.08C158.11 31.35 161.56 33.04 164.97 34.42C168.43 35.78 172.04 36.78 175.7 37.45C183.04 38.79 190.63 38.79 197.99 37.48C205.34 36.15 212.54 33.52 218.67 29.19C221.73 27.03 224.44 24.37 226.57 21.28C228.71 18.2 230.07 14.58 230.55 10.85C230.13 14.59 228.83 18.24 226.73 21.38C224.63 24.52 221.94 27.26 218.89 29.49C212.77 33.96 205.54 36.75 198.13 38.21C190.7 39.66 183 39.79 175.51 38.55C171.76 37.93 168.07 36.96 164.5 35.63C160.91 34.25 157.56 32.66 154.04 31.48C146.98 29.13 139.54 27.98 132.09 27.68C124.63 27.37 117.15 27.82 109.74 28.76C94.9 30.65 80.34 34.48 66.14 39.22L65.2 36.39Z" fill="#80BA1C" />
        <path d="M131.25 19.33C132.57 19.71 133.52 20.73 133.88 21.96C134.08 22.64 133.88 23.34 133.43 23.83C130.98 22.75 130.73 20.07 130.83 20.86C131.04 22.62 132.12 23.63 132.98 24.18C132.86 24.25 132.73 24.31 132.59 24.35C131.57 24.66 130.49 24.08 130.18 23.06C129.87 22.04 128.79 21.46 127.77 21.77C126.75 22.08 126.21 22.81 126.45 21.97C127.04 19.92 129.19 18.73 131.25 19.33Z" fill="#80BA1C" />
        <path d="M152.05 41.06C152.24 38.9 151.28 36.92 149.69 35.69C148.8 35.01 147.65 34.9 146.68 35.29C146.88 39.51 150.68 41.41 149.56 40.82C147.1 39.5 146.21 37.34 145.9 35.76C145.73 35.9 145.57 36.06 145.43 36.24C144.39 37.57 144.63 39.48 145.95 40.52C147.28 41.56 147.51 43.48 146.47 44.8C145.43 46.13 144.05 46.5 145.42 46.62C148.79 46.9 151.75 44.42 152.05 41.06Z" fill="#80BA1C" />
        <path d="M221.22 12.99C221.98 11.85 223.23 11.24 224.51 11.26C225.22 11.27 225.83 11.67 226.16 12.25C224.4 14.26 221.77 13.71 222.55 13.85C224.29 14.17 225.58 13.45 226.36 12.78C226.39 12.92 226.41 13.06 226.4 13.2C226.39 14.27 225.52 15.13 224.45 15.12C223.38 15.11 222.51 15.97 222.5 17.04C222.49 18.11 223.03 18.84 222.3 18.36C220.53 17.18 220.04 14.77 221.22 12.99Z" fill="#80BA1C" />
        <path d="M238.93 10.34C237.7 9.74 236.31 9.85 235.22 10.51C234.62 10.88 234.29 11.53 234.3 12.19C236.84 13.04 238.83 11.23 238.22 11.75C236.88 12.9 235.4 12.94 234.39 12.76C234.43 12.89 234.49 13.02 234.56 13.15C235.11 14.07 236.29 14.37 237.21 13.82C238.13 13.27 239.31 13.57 239.86 14.49C240.41 15.41 240.31 16.31 240.7 15.53C241.64 13.6 240.84 11.28 238.93 10.34Z" fill="#80BA1C" />
        <path d="M215.98 35.63C214.87 34.83 213.47 34.71 212.29 35.18C211.63 35.45 211.2 36.03 211.1 36.69C213.46 37.95 215.73 36.49 215.04 36.9C213.53 37.82 212.06 37.6 211.1 37.26C211.12 37.4 211.16 37.53 211.21 37.67C211.6 38.66 212.72 39.16 213.71 38.77C214.7 38.38 215.82 38.87 216.21 39.87C216.6 40.86 216.35 41.74 216.86 41.03C218.11 39.3 217.71 36.88 215.98 35.63Z" fill="#80BA1C" />
        <path d="M201.39 12.74C202.07 13.16 202.89 13.16 203.56 12.84C203.93 12.66 204.16 12.29 204.18 11.91C202.75 11.28 201.5 12.23 201.88 11.96C202.72 11.36 203.58 11.42 204.16 11.57C204.14 11.49 204.12 11.41 204.08 11.34C203.81 10.78 203.14 10.54 202.57 10.81C202.01 11.08 201.33 10.84 201.06 10.28C200.79 9.72 200.89 9.2 200.63 9.63C199.98 10.7 200.32 12.09 201.39 12.74Z" fill="#80BA1C" />
        <path d="M184.53 12.41C183.22 12.83 182.3 13.87 181.97 15.1C181.79 15.79 182 16.48 182.47 16.95C184.89 15.81 185.07 13.12 184.99 13.91C184.82 15.67 183.78 16.72 182.93 17.29C183.05 17.35 183.18 17.41 183.32 17.45C184.35 17.73 185.41 17.13 185.69 16.1C185.97 15.07 187.04 14.47 188.06 14.75C189.09 15.03 189.65 15.75 189.38 14.92C188.74 12.89 186.56 11.77 184.53 12.41Z" fill="#80BA1C" />
        <path d="M204.27 31.15C205.17 31.17 205.95 31.66 206.39 32.38C206.63 32.78 206.62 33.26 206.42 33.64C204.68 33.36 204.07 31.7 204.26 32.19C204.69 33.27 205.55 33.74 206.19 33.94C206.12 34 206.05 34.06 205.97 34.11C205.37 34.48 204.59 34.29 204.22 33.7C203.85 33.1 203.07 32.92 202.47 33.29C201.87 33.66 201.65 34.22 201.67 33.64C201.7 32.23 202.86 31.11 204.27 31.15Z" fill="#80BA1C" />
        <path d="M198.39 21.78C197.49 21.88 196.65 21.51 196.12 20.86C195.83 20.5 195.77 20.02 195.92 19.61C197.68 19.64 198.52 21.21 198.26 20.75C197.68 19.74 196.77 19.39 196.11 19.28C196.17 19.21 196.23 19.14 196.3 19.08C196.84 18.63 197.64 18.71 198.09 19.25C198.54 19.79 199.34 19.87 199.88 19.42C200.42 18.97 200.56 18.39 200.63 18.96C200.79 20.36 199.78 21.62 198.39 21.78Z" fill="#80BA1C" />
        <path d="M213.42 8.87C213.03 9.25 212.49 9.38 211.99 9.26C211.72 9.19 211.51 8.99 211.43 8.74C212.28 8.11 213.26 8.55 212.97 8.42C212.32 8.15 211.76 8.32 211.4 8.51C211.4 8.45 211.41 8.4 211.42 8.34C211.51 7.93 211.93 7.67 212.34 7.76C212.75 7.85 213.17 7.59 213.26 7.18C213.35 6.77 213.21 6.44 213.45 6.68C214.04 7.3 214.02 8.28 213.42 8.87Z" fill="#80BA1C" />
        <path d="M231.08 0.77C229.98 1.59 229.45 2.88 229.54 4.15C229.59 4.86 230.02 5.45 230.62 5.74C232.53 3.87 231.83 1.27 232.01 2.05C232.43 3.77 231.78 5.1 231.16 5.92C231.3 5.94 231.44 5.95 231.58 5.94C232.64 5.87 233.45 4.95 233.38 3.89C233.31 2.83 234.12 1.91 235.18 1.84C236.24 1.77 237.01 2.27 236.48 1.57C235.22 -0.16 232.79 -0.51 231.08 0.77Z" fill="#80BA1C" />
        <path d="M92.85 30.34C97.88 28.65 103.09 27.51 108.35 26.63C113.63 25.86 118.97 25.48 124.31 25.39C129.65 25.29 134.99 25.67 140.29 26.1C142.91 26.29 145.69 26.77 147.95 26.68C150.36 26.59 152.83 25.98 155.29 25.25C160.23 23.84 165.11 21.75 170.24 20.14C172.8 19.32 175.43 18.62 178.14 18.15C178.82 18.05 179.49 17.91 180.19 17.84L182.27 17.68C183.64 17.66 185.16 17.73 186.35 17.79C191.41 18.1 196.56 17.08 201.18 14.84C205.85 12.7 209.95 9.25 213 4.95L213.17 5.05C210.43 9.6 206.36 13.3 201.66 15.77C196.97 18.25 191.62 19.48 186.31 19.34C181.02 18.98 175.81 20.24 170.82 21.92C165.8 23.56 160.94 25.67 155.85 27.17C153.31 27.91 150.7 28.54 148.03 28.63C145.34 28.7 142.74 28.21 140.11 27.98C134.85 27.44 129.58 27.05 124.3 26.95C113.74 26.77 103.15 27.89 92.91 30.53L92.85 30.34Z" fill="#80BA1C" />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect width="241.09" height="114.63" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}
