import { LOCALES } from "../locales";

export default {
  [LOCALES.FRENCH]: {
    "nav-about": "About",
    "nav-exp": "Edu. && EX",
    "nav-projects": "Mes projects",
    "download": "Télécharger mon CV",
    "view-cv": "Voir mon CV",
    "contacts": "Contacts",
    "button-visitSite": "Visitez le site",
    "greeting-1": "Salut !",
    "greeting-2": "Je suis Valeriya,",
    "greeting-3": "Développeur Frontend ",
    "message": "J'ai eu l'éducation et l'expérience dans le domaine du tourisme. Mais après avoir déménagé en Belgique fin 2017, j'ai décidé de changer mon parcours professionnel vers le développement Web. Je m'intéresse à tout ce qu'il contient. En mars 2020, j'ai terminé un cours de programmation chez BeCode et effectué un stage chez BloomingRiders. Actuellement, je travaille sur mon propre projet - une boutique en ligne de cosmétiques coréens, mais je suis ouverte aux nouvelles opportunités. Je suis amoureuse de monde frontend et je veux continuer à ma vie professionnelle dans ce domaine. J'adore JavaScript et ReactJs, qui est la principale technologie avec laquelle je travaille.",
    "heading-education": "Education && Experience",
    "heading-projects": "Projects",
    "country": "Belgique",
    "country-city": "Charleroi, Belgique",
    "date-korina": "Novembre, 2020 - jusqu'a présent",
    "description-korina": "Mon bébé, ma boutique en ligne de cosmétiques coréens. ReactJS + Next.js.",
    "date-becode": "Septembre, 2019 - mars, 2020",
    "description-becode": "Formation de 7 mois en développement front-end (HTML, CSS, Javascript), développement back-end (PHP, SQL, Adminer), administration de systèmes (Linux), gestion de projet. Auto-apprentissage, autonomie, travail d'équipe.",
    "description-technofuture": "Introduction aux HTML, JavaScript, PHP.",
    "date-blooming": "Avril 2020 - Juillet 2020 ; Janvier 2021 - Février 2021",
    "description-blooming": "Développement front-end de la plateforme LMS (reactJS, gatsbyJS, nodeJS, SASS).",
    "A6K": "C'était un projet de groupe pendant mes études. Le site fonctionne déjà depuis un certain temps, mais nous avons résolus beaucoup de petits problèmes. J'ai travaillé avec du JS (créer des animations, réécrire des fonctions, analyser le code pour supprimer les fonctions inutilisées), mais comme le site a été créé avec le WordPress, j'ai fait du code en PHP et utilisé certaines fonctionnalités de WordPress, MySQL, Adminer.",
    "ANCUBATOR": "C'est mon premier projet personnel en tant que freelance - un site très simple. Technologies: HTML, Bootstrap, Figma, Mailchimp",
    "Blooming Riders": "Ce fut le premier projet vraiment difficile car il consistait à développer un système LMS à partir de zéro. La première partie du projet est un système administratif à travers lequel il est possible de créer des cours avec des leçons, des tests de vérification, des articles, des promotions, des abonnements, des sondages d'utilisateurs. La deuxième partie est la plate-forme elle-même. Les fonctionnalités de l'application : un compte utilisateur personnel avec accès aux fonctionnalités du site selon le niveau de l'utilisateur, un système de paiement a été mis en place, un système d'autorisation par tokens. Technologies : JS, ReactJS, Next.js, Sass",
    "Korina": "Ma propre entreprise est une boutique en ligne de cosmétiques coréens. La première partie du projet est un système administratif avec lequel vous pouvez gérer les marques, les produits, suivre les commandes. La deuxième partie est le magasin. Ici mis en œuvre : compte personnel de l'utilisateur, système de paiement, système d'autorisation par tokens, changement de langue à l'aide du plugin i18n. Technologies : JS, ReactJS, Next.js, Sass.",
    "JobSwap": "Le deuxième projet que j'ai réalisé en tant qu'indépendant. Le projet comprenait le développement du site lui-même et du panneau administratif. J'ai implémenté l'intégralité du frontend. Technologies : ReactJS, Next.js, Sass, Figma. Aussi, pour la première fois, j'ai implémenté la commutation dynamique des langues.",
    "cv-name": "Valeriya Kozlova",
    "cv-post": "Développeur Frontend",
    "cv-location": "Belgique / Russie",
    "cv-skills": "Compétence",
    "cv-perso-skills": "Capacité à travailler à la fois en équipe et de manière autonome, auto-apprentissage rapide",
    "eng": "Anglais - upper intermedaite",
    "fr": "Français - upper intermedaite",
    "ru": "Russe - natif",
    "cv-education": "Formation",
    "tourism-place": "Université Technique d'État de l'Altaï",
    "tourism-ville": "Barnaoul, Russie",
    "tourism-date": "2009-2014",
    "tourism-content": "Master's degree. Tourism.",
    "english-place": "Université Technique d'État de l'Altaï",
    "english-ville": "Barnaoul, Russie",
    "english-date": "2011-2014",
    "english-content": "Cours du soir. Interprète de communication professionnelle (anglais).",
    "techno-place": "Technofutur TIC",
    "techno-ville": "Charleroi, Belgique",
    "techno-date": "2019",
    "techno-content": "Apprentissage à distance. Introduction à HTML, JavaScript, PHP.",
    "becode-place": "BeCode",
    "becode-ville": "Charleroi, Belgique",
    "becode-date": "2019-2020",
    "becode-content": "Formation intensive de 7 mois en développement front-end (HTML, CSS, Javascript), back-end.",
    "experience": "Expériences professionnelles",
    "belgium": "Belgique",
    "cv-ANCUBATOR": "Landing page pour une start-up. Technologies : HTML, Bootstrap, Figma, Mailchimp.",
    "cv-Blooming-Riders": "Développement un système LMS à partir de zéro. La première partie du projet est un système administratif à travers lequel il est possible de créer des cours avec des leçons, des tests de vérification, des articles, des promotions, des abonnements, des sondages d'utilisateurs. La deuxième partie est la plate-forme elle-même. Les fonctionnalités de l'application : un compte utilisateur personnel avec accès aux fonctionnalités du site selon le type d'abonnement de l'utilisateur, un système de paiement a été mis en place, un système d'autorisation par tokens.",
    "date-ANCUBATOR": "Octobre 2019",
    "cv-JobSwap": "Le projet comprenait le développement du site lui-même et du panneau administratif. J'ai implémenté l'intégralité du frontend. Technologies : ReactJS, Next.js, Sass, Figma. Aussi, pour la première fois, j'ai implémenté la commutation dynamique des langues.",
    "date-JobSwap": "Septembre - Octobre 2020",
    "not-found-button": "Retour à la page principale"
  },
};
