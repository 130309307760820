import { LOCALES } from "../locales";

export default {
  [LOCALES.RUSSIAN]: {
    "nav-about": "Обо мне",
    "nav-exp": "Мой путь",
    "nav-projects": "Мои проекты",
    "download": "Скачать мое CV",
    "view-cv": "Посмотреть мое CV",
    "contacts": "Контакты",
    'button-visitSite': "Посетить сайт",
    "greeting-1": "Привет!",
    "greeting-2": "Меня зовут Валерия,",
    "greeting-3": "Frontend разработчик",
    "message": "Я получила образование в сфере туризма, и работала по профессии. Но после переезда в Бельгию в конце 2017 года, я решила изменить свой профессиональный путь в сторону веб-разработки. Меня интересует все, что в ней содержится. В марте 2020 года я закончила курс программирования в BeCode и прошла стажировку в BloomingRiders. Сейчас я работаю над своим проектом - интернет-магазином корейской косметики Korina.be, но я открыта для новых возможностей. Я влюблена во фронтенд разработку и вижу развитие своей карьеры в этом направлении. Мой любимый язык программирования - JavaScript, а ReactJs - это основная технология, с которой я работаю.",
    "heading-education": "Образование && опыт работы",
    "heading-projects": "Проекты",
    "country": "Бельгия",
    "country-city": "Шарлеруа, Бельгия",
    "date-korina": "Ноябрь 2020 - настоящее время",
    "description-korina": "Моя малышка, мой интернет-магазин корейской косметики :) ReactJS + Next.js.",
    "date-becode": "Сентябрь, 2019 - март, 2020",
    "description-becode": "7-ми месячные интенсивные курсы по фронтенд-разработке (HTML, CSS, Javascript), бэкенд-разработке (PHP, SQL, Adminer), системам администрирования(Linux), разработке проектов. Развитие умения автономной работы и обучения, работы в команде.",
    "description-technofuture": "Введение в HTML, JavaScript, PHP.",
    "date-blooming": "Апрель 2020 г. - Июль 2020 г.; Январь 2021 - Февраль 2021.",
    "description-blooming": "Разработка фронтэнда для обучающей платформы (ReactJS, gatsbyJS, nodeJS, Sass).",
    "A6K": "Это был групповой проект во время обучения. Сайт уже работал, но мы решали множество мелких проблем. Я работала с JS (создавала анимацию, переписывала функции, анализировала код для удаления неиспользуемых функций), но, поскольку сайт создавался с помощью WordPress, я делала код на PHP и использовала некоторые функции WordPress, MySQL, Adminer.",
    "ANCUBATOR": "Это мой первый личный проект в качестве фрилансера - очень простой сайт. Технологии: HTML, Bootstrap, Figma, Mailchimp",
    "Blooming Riders": "Это был первый по-настоящему трудный проект, так как он состоял в разработке LMS системы с нуля. Первая часть проекта - административная система, через которую возможно создавать курсы с уроками, проверочные тесты, статьи, промоакции, абонементы, опросы пользователей. Вторая часть - сама платформа. Здесь было реализовано: личный кабинет пользователя с доступом к функционалу сайта в зависимости от уровня пользователя, внедрена система оплаты,  система авторизации с помощью токенов. Технологии: JS, ReactJS, Next.js, Sass",
    "Korina": "Мой собственный бизнес - онлайн магазин корейской косметики. Первая часть проекта - это административная система, с помощью которой можно управлять брендами, продуктами, отслеживать заказы. Вторая часть - это магазин. Здесь реализовано: личный кабинет пользователя, система оплаты, система авторизации с помощью токенов, смена языков спомощью плагина i18n. Технологии: JS, ReactJS, Next.js, Sass.",
    "JobSwap": "Второй проект, который я выполнила как фрилансер. Проект включал в себя разаботку как самого сайта так и административной панели. Я реализовала весь фронтенд. Технологии: JS, ReactJS, Next.js, Sass, Figma. Также в первый раз я реализовала динамичекое переключение языков.",
    "cv-name": "Валерия Козлова",
    "cv-post": "Frontend разработчик",
    "cv-location": "Бельгия / Россия",
    "cv-skills": "Навыки",
    "cv-perso-skills": "Умение работать как в команде, так и автономно, быстрое самообучение",
    "eng": "Английский - upper intermedaite",
    "fr": "Французский - upper intermedaite",
    "ru": "Русский - native",
    "cv-education": "Образование",
    "tourism-place": "Алтайский государственный технический университет",
    "tourism-ville": "Барнаул, Россия",
    "tourism-date": "2009-2014",
    "tourism-content": "Степень магистра. Туризм.",
    "english-place": "Алтайский государственный технический университет",
    "english-ville": "Барнаул, Россия",
    "english-date": "2011-2014",
    "english-content": "Вечерние занятия. Переводчик в сфере профессиональной коммуникации (английский).",
    "techno-place": "Technofutur TIC",
    "techno-ville": " Шарлеруа, Бельгия",
    "techno-date": "2019",
    "techno-content": "Дистанционное обучение. Введение в HTML, JavaScript, PHP.",
    "becode-place": "BeCode",
    "becode-ville": "Шарлеруа, Бельгия",
    "becode-date": "2019-2020",
    "becode-content": "7 месяцев обучения фронтенд-разработке (HTML, CSS, Javascript), back-end.",
    "experience": "Опыт работы",
    "belgium": "Бельгия",
    "cv-ANCUBATOR": "Лендинговая страница для стартапа. Технологии: HTML, Bootstrap, Figma, Mailchimp.",
    "cv-Blooming-Riders": "Разработка LMS системы с нуля. Первая часть проекта - административная система, через которую возможно создавать курсы с уроками, проверочные тесты, статьи, промоакции, абонементы, опросы пользователей. Вторая часть - сама платформа. Здесь было реализовано: личный кабинет пользователя с доступом к функционалу сайта в зависимости от уровня пользователя, внедрена система оплаты, система авторизации с помощью токенов. Технологии: JS, ReactJS, Next.js, Sass.",
    "date-ANCUBATOR": "Октябрь 2019",
    "cv-JobSwap": "Проект включал в себя разаботку как самого сайта так и административной панели. Я реализовала весь фронтенд. Технологии: JS, ReactJS, Next.js, Sass, Figma. Также в первый раз я реализовала динамичекое переключение языков.",
    "date-JobSwap": "Сентябрь - Октябрь 2020",
    "not-found-button": "Вернуться на главную страницу"
  },
};
