import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    "nav-about": "About",
    "nav-exp": "Edu. && EX",
    "nav-projects": "My projects",
    "contacts": "Contacts",
    "download": "Download my CV",
    "view-cv": "View my CV",
    "button-visitSite": "Visit the site",
    "greeting-1": "Hello!",
    "greeting-2": "I am VALERIYA,",
    "greeting-3": "Frontend Developer",
    "message": "I have education and experience in the tourism field. But after moving to Belgium at the end of 2017, I decided to change my professional path verse Web Development. I am interested in everything that it contains. In March 2020, I finished a Programmation course at BeCode and completed an internship at BloomingRiders. Now I work on my project - an online store of Korean Cosmetics, but I am open to new opportunities. I want to improve, first of all, my front-end developer skills. But In the future, I want to become a full-stack developer. I love JavaScript, and ReactJs is the main technology I work with.",
    "heading-education": "Education && Experience",
    "heading-projects": "Projects",
    "country": "Belgium",
    "country-city": "Charleroi, Belgium",
    "date-korina": "November, 2020 - present time",
    "description-korina": "My baby, my Korean cosmetics online shop. ReactJS + Next.js.",
    "date-becode": "September, 2019 - March, 2020",
    "description-becode": "7 months training in front-end development (HTML, CSS, Javascript), back-end development (PHP, SQL, Adminer), systems administration (Linux), project management. Self-learning, autonomy, team-work.",
    "description-technofuture": "Introduction to HTML, JavaScript, PHP.",
    "date-blooming": "April, 2020 - July, 2020; January, 2021 - February, 2021",
    "description-blooming": "Front-end development of the LMS platform (reactJS, gatsbyJS, nodeJS, SASS).",
    "A6K": "It was a group project during my studies. The site has already been working for some time, but we solved a lot of small issues. I worked with JS (created animations, rewrote functions, analyzed the code to delete unused functions), but as the website has been created with WordPress. I did code in PHP and used some functionality of WordPress, MySQL, Adminer.",
    "ANCUBATOR": "It is my first project as a freelancer - a very simple site. Technologies: HTML, Bootstrap, Figma, Mailchimp.",
    "Blooming Riders": "It was the first difficult and big project as it consisted of developing an LMS system from scratch. The first part of the project is an administrative system that allows creating courses with lessons, verification tests, articles, promotions, subscriptions, user polls. The second part is the platform itself. There ware implemented: a user's account with access to the site's functionality depending on the user's level, a payment system, an authorization system using tokens. Technologies: JS, ReactJS, Next.js, Sass.",
    "Korina": "My own business - a Korean cosmetics online shop. The first part of the project is an administrative system that allows managing brands, products, orders. The second part is the store. Implemented here: user's account, payment system, authorization system using tokens, changing languages ​​using the i18n plugin. Technologies: JS, ReactJS, Next.js, Sass.",
    "date-JobSwap": "September - October 2020",
    "JobSwap": "The second project that I have done as a freelancer. The project included the development of both the site itself and the administrative panel. I have created the entire frontend. Technologies: ReactJS, Next.js, Sass, Figma. Also for the first time, I have implemented dynamic language switching.",
    "cv-name": "Valeriya Kozlova",
    "cv-post": "Frontend developer",
    "cv-location": "Belgium / Russia",
    "cv-skills": "Skills",
    "cv-perso-skills": "Ability to work both in a team and autonomously, quick self-learning",
    "eng": "English - upper intermedaite",
    "fr": "French - upper intermedaite",
    "ru": "Russian - native",
    "cv-education": "Education",
    "tourism-place": "Altai State Technical University",
    "tourism-ville": "Barnaul, Russia",
    "tourism-date": "2009-2014",
    "tourism-content": "Master's degree. Tourism.",
    "english-place": "Altai State Technical University",
    "english-ville": "Barnaul, Russia",
    "english-date": "2011-2014",
    "english-content": "Evening classes. Professional communication interpreter (English).",
    "techno-place": "Technofutur TIC",
    "techno-ville": "Charleroi, Belgium",
    "techno-date": "2019",
    "techno-content": "Distance learning. Introduction to HTML, JavaScript, PHP.",
    "becode-place": "BeCode",
    "becode-ville": "Charleroi, Belgium",
    "becode-date": "2019-2020",
    "becode-content": "7 months training in front-end development (HTML, CSS, Javascript), back-end.",
    "experience": "Experience",
    "belgium": "Belgium",
    "cv-ANCUBATOR": "Landing page for a start-up. Technologies: HTML, Bootstrap, Figma, Mailchimp.",
    "cv-Blooming-Riders": "Developing an LMS system from scratch. The first part of the project is an administrative system through which it is possible to create courses with lessons, verification tests, articles, promotions, subscriptions, user polls. The second part is the platform itself. Here it was implemented: a user's personal account with access to the site's functionality depending on the user's level, a payment system, an authorization system using tokens. Technologies: JS, ReactJS, Next.js, Sass.",
    "date-ANCUBATOR": "October 2019",
    "cv-JobSwap": "The project included the development of both the site itself and the administrative panel. I have created the entire frontend. Technologies: ReactJS, Next.js, Sass, Figma. Also for the first time I have implemented dynamic language switching.",
    "not-found-button": "Go back to the main page"
  },
};
