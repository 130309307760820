import React from "react"

function LogoAncubator() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 85 41"
      className="width"
    >
      <path
        d="M16.3179 16.2339L21.9355 15.8838V0.249998H16.3179V16.2339Z"
        fill="#1E6FF1"
        stroke="#1E6FF1"
        strokeWidth="0.5"
      />
      <path
        d="M12.4297 25.4094L22.0195 9.53674e-06H16.0672L5.73331 25.4094H12.4297Z"
        fill="black"
      />
      <path
        d="M19.8092 24.5425H17.7016L17.3335 25.7343H15.6904L18.0302 19.3546H19.4762L21.8335 25.7343H20.1816L19.8092 24.5425ZM18.0697 23.3551H19.4411L18.7532 21.1423L18.0697 23.3551Z"
        fill="black"
      />
      <path
        d="M28.2536 25.7343H26.7244L24.4635 21.7645V25.7343H22.9255V19.3546H24.4635L26.72 23.3244V19.3546H28.2536V25.7343Z"
        fill="black"
      />
      <path
        d="M37.6585 23.71C37.5797 24.3848 37.3299 24.9062 36.9093 25.2742C36.4916 25.6394 35.9351 25.8219 35.2399 25.8219C34.4862 25.8219 33.8816 25.5517 33.4259 25.0113C32.9731 24.4709 32.7467 23.748 32.7467 22.8424V22.229C32.7467 21.636 32.8519 21.1146 33.0622 20.6647C33.2754 20.2149 33.5763 19.8702 33.9648 19.6307C34.3533 19.3882 34.8032 19.267 35.3144 19.267C35.992 19.267 36.5354 19.4569 36.9443 19.8366C37.3533 20.2134 37.5913 20.7363 37.6585 21.4052H36.8129C36.7398 20.897 36.5806 20.5289 36.3353 20.3011C36.0928 20.0732 35.7525 19.9593 35.3144 19.9593C34.7769 19.9593 34.3548 20.1579 34.0481 20.5552C33.7443 20.9525 33.5924 21.5177 33.5924 22.2509V22.8687C33.5924 23.561 33.737 24.1116 34.0261 24.5206C34.3153 24.9295 34.7199 25.134 35.2399 25.134C35.7072 25.134 36.0651 25.0289 36.3134 24.8185C36.5646 24.6053 36.7311 24.2358 36.8129 23.71H37.6585Z"
        fill="black"
      />
      <path
        d="M43.9253 19.3546V23.6925C43.9223 24.2942 43.7325 24.7864 43.3556 25.1691C42.9817 25.5517 42.4735 25.7664 41.8308 25.8132L41.6074 25.8219C40.9092 25.8219 40.3528 25.6335 39.938 25.2567C39.5232 24.8799 39.3128 24.3614 39.307 23.7012V19.3546H40.1395V23.6749C40.1395 24.1365 40.2666 24.4958 40.5207 24.7528C40.7749 25.007 41.1371 25.134 41.6074 25.134C42.0835 25.134 42.4472 25.007 42.6984 24.7528C42.9525 24.4987 43.0796 24.1408 43.0796 23.6793V19.3546H43.9253Z"
        fill="black"
      />
      <path
        d="M45.8761 25.7343V19.3546H47.9617C48.654 19.3546 49.174 19.4978 49.5216 19.784C49.8721 20.0703 50.0474 20.4939 50.0474 21.0547C50.0474 21.3527 49.9627 21.617 49.7933 21.8478C49.6238 22.0756 49.3931 22.2524 49.101 22.378C49.4456 22.4744 49.7173 22.6584 49.9159 22.9301C50.1175 23.1988 50.2183 23.5201 50.2183 23.894C50.2183 24.4665 50.0328 24.9164 49.6618 25.2436C49.2908 25.5707 48.7665 25.7343 48.0888 25.7343H45.8761ZM46.7173 22.7504V25.0464H48.1063C48.4977 25.0464 48.8059 24.9456 49.0308 24.7441C49.2587 24.5396 49.3726 24.2592 49.3726 23.9028C49.3726 23.1345 48.9549 22.7504 48.1195 22.7504H46.7173ZM46.7173 22.0756H47.988C48.3561 22.0756 48.6496 21.9836 48.8687 21.7996C49.0907 21.6156 49.2017 21.3658 49.2017 21.0503C49.2017 20.6998 49.0995 20.4457 48.895 20.2879C48.6905 20.1273 48.3794 20.0469 47.9617 20.0469H46.7173V22.0756Z"
        fill="black"
      />
      <path
        d="M55.613 24.0693H52.9402L52.34 25.7343H51.4724L53.9086 19.3546H54.6447L57.0853 25.7343H56.2221L55.613 24.0693ZM53.1944 23.377H55.3633L54.2766 20.3931L53.1944 23.377Z"
        fill="black"
      />
      <path
        d="M62.4056 20.0469H60.355V25.7343H59.5181V20.0469H57.4718V19.3546H62.4056V20.0469Z"
        fill="black"
      />
      <path
        d="M68.7599 22.7504C68.7599 23.3755 68.6548 23.9218 68.4445 24.3891C68.2341 24.8536 67.9362 25.2085 67.5506 25.4539C67.165 25.6993 66.7152 25.8219 66.201 25.8219C65.6986 25.8219 65.2532 25.6993 64.8646 25.4539C64.4761 25.2056 64.1738 24.8536 63.9576 24.3979C63.7444 23.9393 63.6349 23.4091 63.629 22.8074V22.3473C63.629 21.7339 63.7356 21.192 63.9489 20.7217C64.1621 20.2514 64.463 19.8921 64.8515 19.6438C65.2429 19.3926 65.6899 19.267 66.1923 19.267C66.7035 19.267 67.1533 19.3911 67.5418 19.6394C67.9333 19.8848 68.2341 20.2426 68.4445 20.7129C68.6548 21.1803 68.7599 21.7251 68.7599 22.3473V22.7504ZM67.923 22.3385C67.923 21.582 67.7711 21.0021 67.4673 20.599C67.1636 20.193 66.7385 19.99 66.1923 19.99C65.6606 19.99 65.2415 20.193 64.9348 20.599C64.631 21.0021 64.4747 21.563 64.4659 22.2816V22.7504C64.4659 23.4836 64.6193 24.0605 64.926 24.4812C65.2356 24.8989 65.6606 25.1077 66.201 25.1077C66.7444 25.1077 67.165 24.9106 67.463 24.5162C67.7609 24.1189 67.9143 23.5508 67.923 22.8117V22.3385Z"
        fill="black"
      />
      <path
        d="M72.9848 23.1535H71.4863V25.7343H70.6406V19.3546H72.7526C73.4712 19.3546 74.0233 19.5182 74.4088 19.8454C74.7973 20.1725 74.9916 20.6487 74.9916 21.2738C74.9916 21.6711 74.8835 22.0172 74.6674 22.3122C74.4541 22.6073 74.1562 22.8278 73.7735 22.9739L75.272 25.6817V25.7343H74.3694L72.9848 23.1535ZM71.4863 22.4656H72.7789C73.1966 22.4656 73.5281 22.3575 73.7735 22.1414C74.0218 21.9252 74.1459 21.636 74.1459 21.2738C74.1459 20.8794 74.0276 20.5771 73.791 20.3668C73.5573 20.1565 73.2185 20.0499 72.7745 20.0469H71.4863V22.4656Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipule="evenodd"
        d="M0.997067 18.4426V39.3955L84.0029 28.3714V11.8662L0.997067 18.4426ZM0 17.5214V40.5337L85 29.2448V10.787L0 17.5214Z"
        fill="black"
      />
    </svg>
  )
}

export default LogoAncubator
